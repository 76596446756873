/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import "react-input-range/lib/css/index.css";
import Spreadsheet from "react-spreadsheet";
import { Category } from "@material-ui/icons";
import { dealPreviewMapFields } from "../../../../../servies/services";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SearchIcon from "@material-ui/icons/Search";
import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { borderTop } from "@mui/system";
import FilterMappedTape from "../../../../../images/FilterMappedTape.png";
import borderLeft from "../../../../../images/border-left.png";
import borderBottom from "../../../../../images/border-bottom.png";
import rightArrow from "../../../../../images/RightPointer.png";

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
class Preview_Mapped_fields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loading: false,
      tableData: [],
      DealName:
        sessionStorage.getItem("dealname") === null
          ? sessionStorage.getItem("servicerdealname")
          : sessionStorage.getItem("dealname"),
          DealName:
          sessionStorage.getItem("dealname") === null
            ? sessionStorage.getItem("servicerdealname")
            : sessionStorage.getItem("dealname"),
            ServicerName:
            sessionStorage.getItem("Servicer") === null
              ? sessionStorage.getItem("servicerdashboardname")
              : sessionStorage.getItem("Servicer"),
               servicerDealName:
            this.props.location?.state?.ServicerDealName === undefined
              ? sessionStorage.getItem("servicerdealname")
              : this.props.location?.state?.ServicerDealName,
               servicerDashboardName:
            this.props.location?.state?.ServicerDashboardName === undefined
              ? sessionStorage.getItem("servicerdashboardname")
              : this.props.location?.state?.ServicerDashboardName,
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "",
      sortAscending: true,
      sortDescending: true,
      sortEmpty: true,
      sortNonEmpty: true,
      sorterrorfields: "",
      isFilterActive: false,
      overlayVisible: false,
      activeColumn: null
    };
  }

  // handleButtonClick = (column) => {
  //   this.setState((prevState) => ({
  //     overlayVisible: !prevState.overlayVisible,
  //   }));
  // };

  handleButtonClick = (column) => {
    this.setState((prevState) => ({
      activeColumn: column, // Set the active column
      overlayVisible: !prevState.overlayVisible, // Toggle overlay visibility
    }));
  };
  

  handleOverlayHide = () => {
    this.setState({ overlayVisible: false });
  };

  sortA = (sortColumn) => {
    console.log("", this.state.MapDataKeys);
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click()
  };

  // Function to sort in descending order
  sortZ = (sortColumn) => {
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) return -1;
      if (a[sortColumn] < b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click()
  };

  // Function to filter rows where the column is empty
  sortEmpty = (sortColumn) => {
    const { tableData } = this.state;
    const filteredData = tableData.filter((row) => !row[sortColumn]);

    if (filteredData.length > 0) {
      this.setState({ tableData: filteredData });
    } else {
      this.setState({ tableData });
    }
    document.body.click()
  };

  sortNonEmpty = (sortColumn) => {
    const { tableData } = this.state;
    const filteredData = tableData.filter((row) => row[sortColumn]);
    if (filteredData.length > 0) {
      this.setState({ tableData: filteredData });
    } else {
      this.setState({ tableData });
    }
    document.body.click()
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterActive: !prevState.isFilterActive,
    }));
  };

  // popoverBottom = (column) => {
  //   const isFilterActive = this.state.isFilterActive;
  //   const triangleImage = isFilterActive ? rightArrow : rightArrow;

  //   return (
  //     <Popover
  //       className={
  //         isFilterActive
  //           ? "servicer-popover-container-sort-2"
  //           : "servicer-popover-container-sort"
  //       }
  //     >
  //       <button onClick={() => this.sortA(column)}>Sort A to Z</button>
  //       <hr className="servicer-popover-hr" />
  //       <button onClick={() => this.sortZ(column)}>Sort Z to A</button>
  //       <hr className="servicer-popover-hr" />
  //       <button onClick={this.toggleFilter}>
  //         Filter
  //         <img
  //           src={triangleImage}
  //           className="popover-triangle"
  //           alt="Filter Toggle"
  //         />
  //       </button>
  //       {isFilterActive && (
  //         <div className="servicer-popover-container-filter">
  //           <button onClick={() => this.sortEmpty(column)}>Is Empty</button>
  //           <hr className="servicer-popover-sort" />
  //           <button onClick={() => this.sortNonEmpty(column)}>
  //             Is Not Empty
  //           </button>
  //         </div>
  //       )}
  //     </Popover>
  //   );
  // };

  popoverBottom = (column) => {
    const isFilterActive = this.state.isFilterActive;
    const triangleImage = isFilterActive ? rightArrow : rightArrow;

    return (
      <Popover
        className={
          isFilterActive
            ? "servicer-popover-container-sort-2"
            : "servicer-popover-container-sort"
        }
      >
        <button onClick={() => this.sortA(column)}>Sort A to Z</button>
        <hr className="servicer-popover-hr" />
        <button onClick={() => this.sortZ(column)}>Sort Z to A</button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.toggleFilter}>
          Filter
          <img
            src={triangleImage}
            className="popover-triangle"
            alt="Filter Toggle"
          />
        </button>
        {isFilterActive && (
          <div className="servicer-popover-container-filter">
            <button onClick={() => this.sortEmpty(column)}>Is Empty</button>
            <hr className="servicer-popover-sort" />
            <button onClick={() => this.sortNonEmpty(column)}>
              Is Not Empty
            </button>
          </div>
        )}
      </Popover>
    );
  };


  handleSort = (column) => {
    const { sortColumn, sortAscending, tableData } = this.state;

    let newSortAscending;
    if (sortColumn === column) {
      newSortAscending = !sortAscending;
    } else {
      newSortAscending = true;
    }

    const compareFunc = (a, b) => {
      // Special case for sorting by S.No
      if (column === "S.No") {
        const hasErrorA = a.ErrorFields && a.ErrorFields.length > 0;
        const hasErrorB = b.ErrorFields && b.ErrorFields.length > 0;

        if (hasErrorA && !hasErrorB) return newSortAscending ? -1 : 1;
        if (!hasErrorA && hasErrorB) return newSortAscending ? 1 : -1;
        return 0;
      }

      const valueA = a[column] ? a[column].toLowerCase() : "";
      const valueB = b[column] ? b[column].toLowerCase() : "";
      if (valueA < valueB) return newSortAscending ? -1 : 1;
      if (valueA > valueB) return newSortAscending ? 1 : -1;
      return 0;
    };

    const sortedTableData = [...tableData].sort(compareFunc);

    this.setState({
      tableData: sortedTableData,
      sortColumn: column,
      sortAscending: newSortAscending,
    });
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  toggleFullScreen = () => {
    this.setState((prevState) => ({
      isFullScreen: !prevState.isFullScreen,
    }));

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  handleClickUploadLoanTapes = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
    window.location.assign("/admin/uploadloantape");
  };

  handleClickPreviewLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
    window.location.assign("/admin/previewloantapedetails");
  };
  handleClickMapFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
    });
    window.location.assign("/admin/mapfields");
  };
  handleClickPreviewMappedFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
    });
  };
  handleClickSummarize = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
    });
    window.location.assign("/admin/summarize");
  };
  // dealPreviewMapFields = async () => {
  //   let x = moment(this.state.detailsDate)
  //     .subtract(1, "months")
  //     .format("MM/DD/YYYY")
  //     .toString();
  //   let month = x.slice(0, 2);
  //   let year = x.slice(6, 10);
  //   var data = {};
  //   if (sessionStorage.getItem("user_name") === "Trustee") {
  //     data.DealName = this.state.DealName;
  //     data.peer = this.state.peer;
  //   } else {
  //     data.DealName = this.state.servicerDealName || this.state.servicerDashboardName;
  //     data.peer = this.state.peer;
  //   }
  //   data.Month = month;
  //   data.Year = year;
  //   data.ServicerName = this.state.ServicerName;
  //   data.ClosingTape = JSON.parse(sessionStorage.getItem("isClosingTape"))
  //     ? true
  //     : false;
  //   data.peer = this.state.peer;
  
  //   this.setState({ screenloader: true });
  //   const APIResponse = await dealPreviewMapFields(data);
  
  //   if (APIResponse.status === 200) {
  //     if (APIResponse.data.isSuccess === true) {
  //       const tableData = APIResponse.data?.result || [];
  
  //       if (!tableData.some((item) => "Loan ID" in item)) {
  //         this.setState({ screenloader: false });
  //         this.props.enqueueSnackbar("Please map the Loan ID in the Map Fields section, as it is a mandatory field", {
  //           variant: "error",
  //           autoHideDuration: 6000,
  //         });
  //         return;
  //       }
  
  //       const hasErrorFields =
  //         tableData.length > 0 && "ErrorFields" in tableData[0];
  
  //       // this.setState({
  //       //   MapDataKeys:
  //       //     tableData.length > 0
  //       //       ? Object.keys(tableData[0]).filter((key) => key !== "ErrorFields")
  //       //       : [],
  //       //   MapDataValues: tableData.map((item) =>
  //       //     Object.keys(item).map((key) => ({ value: item[key] }))
  //       //   ),
  //       //   screenloader: false,
  //       //   tableData: tableData.length > 0 ? tableData : ["no data"],
  //       // });
  //       this.setState({
  //         MapDataKeys:
  //           tableData.length > 0
  //             ? Object.keys(tableData[0]).filter((key) => key !== "ErrorFields")
  //             : [],
  //         MapDataValues: tableData.map((item) =>
  //           Object.keys(item).map((key) => ({
  //             value: typeof item[key] === "boolean" ? item[key].toString() : item[key],
  //           }))
  //         ),
  //         screenloader: false,
  //         tableData: tableData.length > 0 ? tableData : ["no data"],
  //       });
        
  //     } else {
  //       this.setState({ screenloader: false });
  //       const message = APIResponse.data.result;
  //       this.props.enqueueSnackbar(message, {
  //         variant: "error",
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   } else {
  //     this.setState({ screenloader: false });
  //     const message = APIResponse.data.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // };

  dealPreviewMapFields = async () => {
    let x = moment(this.state.detailsDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
  
    if (sessionStorage.getItem("user_name") === "Trustee") {
      data.DealName = this.state.DealName;
      data.peer = this.state.peer;
    } else {
      data.DealName = this.state.servicerDealName || this.state.servicerDashboardName;
      data.peer = this.state.peer;
    }
  
    data.Month = month;
    data.Year = year;
    data.ServicerName = this.state.ServicerName;
    data.ClosingTape = JSON.parse(sessionStorage.getItem("isClosingTape")) ? true : false;
  
    this.setState({ screenloader: true });
  
    const APIResponse = await dealPreviewMapFields(data);
  
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const tableData = APIResponse.data?.result || [];
  
        if (!tableData.some((item) => "Loan ID" in item)) {
          this.setState({ screenloader: false });
          this.props.enqueueSnackbar("Please map the Loan ID in the Map Fields section, as it is a mandatory field", {
            variant: "error",
            autoHideDuration: 6000,
          });
          return;
        }
  
        const hasErrorFields = tableData.length > 0 && "ErrorFields" in tableData[0];
  
        this.setState({
          MapDataKeys: tableData.length > 0 
            ? Object.keys(tableData[0]).filter((key) => key !== "ErrorFields")
            : [],
          MapDataValues: tableData.map((item) =>
            Object.keys(item).map((key) => ({
              value: typeof item[key] === "boolean" ? item[key].toString() : item[key] || 'N/A',
            }))
          ),
          screenloader: false,
          tableData: tableData.length > 0 ? tableData : ["no data"],
        });
        
  
      } else {
        this.setState({ screenloader: false });
        const message = APIResponse.data.result;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  
  

  goBackToDashBoard = () => {
    if (sessionStorage.getItem("user_name") === "Trustee") {
      this.props.history.push({
        pathname: "/admin/viewdetails",
        state: { details: this.state.detailsDate },
      });
      sessionStorage.setItem("isClosingTape", false);
    } else {
      this.props.history.push({
        pathname: "/dashboard/servicer",
      });
      sessionStorage.setItem("isClosingTape", false);
    }
  };

  componentDidMount() {
    this.dealPreviewMapFields();
  }

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;
    const isNumericSearch = !isNaN(searchTerm) && searchTerm.trim() !== "";

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (row[key]) {
          const cellValue = row[key].toString().toLowerCase();
          if (isNumericSearch && !isNaN(row[key])) {
            if (parseFloat(cellValue) === parseFloat(searchTerm)) {
              return true;
            }
          } else if (cellValue.includes(searchTerm)) {
            return true;
          }
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData,
    });
  };

  // filterData = (searchTerm) => {
  //   const { tableData } = this.state;

  //   const filteredData = tableData.filter((row) =>
  //     Object.values(row).some((value) => {
  //       if (typeof value === "string") {
  //         return value.toLowerCase().includes(searchTerm.toLowerCase());
  //       }
  //       return false;
  //     })
  //   );

  //   return filteredData;
  // };

  filterData = (searchTerm) => {
    const { tableData } = this.state;
  
    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) => {
        if (value === null || value === undefined) {
          return false; // Skip null or undefined values
        }
        
        const stringValue = value.toString().toLowerCase(); // Convert everything to string
  
        return stringValue.includes(searchTerm.toLowerCase());
      })
    );
  
    return filteredData;
  };
  

  checkForErrors = () => {
    const { tableData } = this.state;
    return tableData.some(
      (row) => row.ErrorFields && row.ErrorFields.length > 0
    );
  };

  render() {
    const { overlayVisible } = this.state;

    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
      sortDescending,
      sortEmpty,
      sortNonEmpty,
    } = this.state;
    const borderStyle = (hasError) => ({
      backgroundImage: hasError ? `url(${borderLeft})` : "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "8px 80%",
      borderLeft: "none",
      // borderBottom: hasError ? 'none' : '1px solid #e0e0e0',
    });

    const borderStylebottom = (hasError) => ({
      backgroundImage: hasError ? `url(${borderBottom})` : "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom left",
      backgroundSize: "100% 6px", // Adjust size to fit your needs
      borderBottom: "none", // Remove default border-bottom
      borderLeft: "8px solid #fff",
      borderRight: "8px solid #fff",
    });

    const filteredData = searchTerm ? this.filterData(searchTerm) : tableData;
    const hasErrors = this.checkForErrors();

    return (
      <React.Fragment>
        <div className={`page ${overlayVisible ? "blur-background" : ""}`}>
          <Sidebar
            activeComponent={
              sessionStorage.getItem("user_name") === "Servicer"
                ? "Servicer Dashboard"
                : "Dashboard"
            }
          />
          <div className="content">
            
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div className="col-12 col-sm-6 col-md-2 d-flex justigy-content-center align-center hellocard">
                    <KeyboardBackspaceIcon
                      onClick={() => this.goBackToDashBoard()}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">SERVCER DETAILS</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-md-10 hellocard">
                    <div className="recurring_details">
                      <div>
                        <label className="dealInfo">Deal Name : </label>
                        <h6 className="dealInfo1">{this.state.DealName}</h6>
                      </div>
                      <div>
                        <label className="dealInfo">Payment Date : </label>
                        <h6 className="dealInfo1">{this.state.detailsDate}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1">
                  <div>
                    <div className="tablechangebuttonloans">
                      <button
                        type="button"
                        onClick={() => this.handleClickUploadLoanTapes()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        Upload Loan Tapes
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewLoanTape()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Preview Loan Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickMapFields()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Map Fields
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewMappedFields()}
                        className={
                          hasErrors
                            ? "issuerDashboard-table-top-button-rejected-error-active"
                            : this.state.activeInsights4
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Mapped Tapes
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickSummarize()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Summarize
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <div>
                      <div className="search-container active">
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={this.handleSearch}
                          placeholder="Search..."
                          className="search-input"
                        />
                        <span
                          className="search-icon"
                          onClick={this.handleSearchIconClick}
                        >
                          <SearchIcon />
                        </span>
                      </div>

                      {!isDataFound && <p>No data found.</p>}
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ width: "7rem" }}
                      onClick={() => this.handleClickSummarize()}
                    >
                      Next
                    </Button>
                  </div>
                </div>

                {this.state.screenloader == true ? (
                  <LinearLoader></LinearLoader>
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        overflow: "auto",
                        borderRadius: "10px",
                        border: "0.5px solid black",
                      }}
                    >
                      <TableVirtuoso
                        styles={{ overflowAnchor: "none" }}
                        style={{ height: 800 }}
                        data={filteredData}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                          <TableRow>
                            {/* First Fixed Column Header (Index Column) */}
                            <TableCell
                              style={{
                                background: "white",
                                width: "50px",
                                position: "sticky",
                                left: 0,
                                zIndex: 999,
                                cursor: "pointer", // Add cursor pointer to indicate clickable column
                                borderRight: "1px solid #ddd",
                              }}
                              onClick={() => this.handleSort("S.No")} // Trigger sort on click
                            >
                              <div style={{ display: "flex", gap: "6px" }}>
                                {sortColumn === "S.No" && (
                                  <span>{sortAscending}</span>
                                )}
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                position: "sticky",
                                left: "50px",
                                zIndex: 999,
                                width: "100px",
                                background: "white",
                                cursor: "pointer",
                                 borderRight: "1px solid #ddd",
                              }}
                              onClick={() => this.handleSort("Loan ID")}
                            >
                              <div style={{ display: "flex", gap: "6px" }}>
                                Loan ID
                                {sortColumn === "Loan ID" && (
                                  <span>{sortAscending}</span>
                                )}
                              </div>
                            </TableCell>
                            {MapDataKeys.slice(1).map((column, index) => (
                              <TableCell
                                key={index}
                                style={{
                                  background: "white",
                                  cursor: "pointer",
                                  width: `${column.length * 10}px`,
                                  whiteSpace: "nowrap",
                                   borderRight: "1px solid #ddd",
                                  // filter: this.state.activeColumn && this.state.activeColumn !== column ? "blur(5px)" : "none",
                                }}
                              >
                                <div>
                                  {column}
                                  <div>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="right"
                                      overlay={this.popoverBottom(column)}
                                      onExited={this.handleOverlayHide}
                                      rootClose={true}
                                    >
                                      <img
                                        src={FilterMappedTape}
                                        alt="Filter"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.handleButtonClick}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                        )}
                        itemContent={(index, row) => (
                          <>
                            <TableCell
                              className={`sticky-column ${
                                row.ErrorFields?.length > 0
                                  ? "error"
                                  : "no-error"
                              }`}
                              style={borderStyle(row.ErrorFields?.length > 0)}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              style={{
                                background: "white",
                                position: "sticky",
                                left: "50px", // Same as the width of the first column
                                zIndex: 1,
                                width: "150px", // Adjust width as needed
                              borderRight: "1px solid #ddd",
                              }}
                            >
                              {row["Loan ID"]}
                            </TableCell>
                            {/* Other Columns */}
                            {/* {MapDataKeys.slice(1).map((column, columnIndex) => (
                              <TableCell
                                key={columnIndex}
                                style={{
                                  position: "relative",
                                  ...borderStylebottom(
                                    row.ErrorFields?.includes(column)
                                  ),
                                  width: `${column.length * 10}px`,
                                  whiteSpace: "nowrap",
                                }}
                                className="TableCell"
                              >
                                {row[column]}
                                {row.ErrorFields?.includes(column) && (
                                  <div className="Mappedtape-err-validations-msg">
                                    {column === "Advance Rate-A" ||
                                    column === "Advance Rate-B"
                                      ? "Advance Rate is greater than 80%"
                                      : column === "PropertySquareFt"
                                      ? "Property Square Ft less than 100 or greater than 10000"
                                      : column === "Property Year Built"
                                      ? "Property built before 1960"
                                      : column === "Bed"
                                      ? "Number of Bedroom greater than 10 or no bedroom"
                                      : column === "Bath"
                                      ? "Number of Bathroom greater than 10 or no bathroom"
                                      : column === "Acquisition Price"
                                      ? "Purchase Price greater than 1.1 mn or less than 80k"
                                      : column === "Estimated Renovation Cost"
                                      ? "Renovation Cost greater than 50% of Initial Purchase Price"
                                      : column === "Original List Price"
                                      ? "Price is less than 80% of Origination Valuation Amount"
                                      : column === "Current List Price"
                                      ? "Price is less than 70% of Origination Valuation Amount"
                                      : column === "Actual Reno Expenses"
                                      ? "Renovation Expenses less than Post Renovation Advance Amount"
                                      : "Error in this field the value is incorrect"}

                                    <div className="Mappedtape-err-validations-msg-pointer" />
                                  </div>
                                )}
                              </TableCell>
                            ))} */}
                            {MapDataKeys.slice(1).map((column, columnIndex) => (
  <TableCell
    key={columnIndex}
    style={{
      position: "relative",
      ...borderStylebottom(row.ErrorFields?.includes(column)),
      width: `${column.length * 10}px`,
      whiteSpace: "nowrap",
       borderRight: "1px solid #ddd",
       zIndex: 1,
    }}
    className="TableCell"
  >
    {typeof row[column] === 'boolean' ? row[column].toString() : row[column]} {/* Handle boolean values */}
    {row.ErrorFields?.includes(column) && (
      <div className="Mappedtape-err-validations-msg">
        {column === "Advance Rate-A" ||
        column === "Advance Rate-B"
          ? "Advance Rate is greater than 80%"
          : column === "PropertySquareFt"
          ? "Property Square Ft less than 100 or greater than 10000"
          : column === "Property Year Built"
          ? "Property built before 1960"
          : column === "Bed"
          ? "Number of Bedroom greater than 10 or no bedroom"
          : column === "Bath"
          ? "Number of Bathroom greater than 10 or no bathroom"
          : column === "Acquisition Price"
          ? "Purchase Price greater than 1.1 mn or less than 80k"
          : column === "Estimated Renovation Cost"
          ? "Renovation Cost greater than 50% of Initial Purchase Price"
          : column === "Original List Price"
          ? "Price is less than 80% of Origination Valuation Amount"
          : column === "Current List Price"
          ? "Price is less than 70% of Origination Valuation Amount"
          : column === "Actual Reno Expenses"
          ? "Renovation Expenses less than Post Renovation Advance Amount"
          : "Error in this field the value is incorrect"}

        <div className="Mappedtape-err-validations-msg-pointer" />
      </div>
    )}
  </TableCell>
))}

                          </>
                        )}
                      />
                    </div>

                    <div></div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        {overlayVisible && (
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              zIndex: 999,
            }}
            onClick={this.handleOverlayHide}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withSnackbar(Preview_Mapped_fields);
